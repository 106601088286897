import { get } from '_common/service/ApiService';

export const getResultatsRechercheGlobale = async (termes) => {
    return await get(process.env.REACT_APP_API_URL + `/api/recherche?termes=${termes}`);
};

export const getResultatsRechercheAdherentes = async (termes, pagenum) => {
    return await get(process.env.REACT_APP_API_URL + `/api/recherche/adherente?termes=${termes}&page=${pagenum}`);
};

export const getResultatsRechercheCoupDePouces = async (termes, pagenum) => {
    return await get(process.env.REACT_APP_API_URL + `/api/recherche/coupdepouce?termes=${termes}&page=${pagenum}`);
};

export const getResultatsRechercheEvenements = async (termes, pagenum) => {
    return await get(process.env.REACT_APP_API_URL + `/api/recherche/evenement?termes=${termes}&page=${pagenum}`);
};

export const getResultatsRecherchePublications = async (termes, pagenum) => {
    return await get(process.env.REACT_APP_API_URL + `/api/recherche/publication?termes=${termes}&page=${pagenum}`);
};

export const getResultatsRechercheTerritoires = async (termes, pagenum) => {
    return await get(process.env.REACT_APP_API_URL + `/api/recherche/territoire?termes=${termes}&page=${pagenum}`);
};

export const getResultatsRechercheRessources = async (termes, pagenum) => {
    return await get(process.env.REACT_APP_API_URL + `/api/recherche/ressource?termes=${termes}&page=${pagenum}`);
};
