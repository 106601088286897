import { get, del, post } from '_common/service/ApiService';

export const getVilles = async (codePostal) => {
    return await get(process.env.REACT_APP_API_URL + `/api/villes?codePostal=${codePostal}`);
};

export const checkConfirmationToken = async (verifyToken) => {
    return await post(
        process.env.REACT_APP_API_URL + `/api/changement_email`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'token': verifyToken }),
        },
    );
};

let routeSuppressionMembre = process.env.REACT_APP_API_URL + '/api/adherentes/{id}';
export const saveDeleteMembre = async (id) => {
    const formData = new FormData();
    formData.append('id', id);
    const url = routeSuppressionMembre.replace('{id}', id);
    return await del(url, {
        body: formData,
    });
};
