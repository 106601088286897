import { useNavigate, useLocation } from 'react-router-dom';
import {
    ROUTE_HOME,
} from '_core/router/routes';
import notificationService from '_common/component/toaster/NotificationService';
import { checkConfirmationToken } from '../../authentification/service/AuthServiceApi';
import { useEffect, useState } from 'react';

const ChangementEmail = () => {

    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    let tokenVerification = null;
    
    const verifyConfirmationTokenChangementEmail = async (token) => {
        try {
            const result = await checkConfirmationToken(token);

            if (result.message) {
                notificationService.success('Ton changement d\'adresse e-mail est validé.');
            }
            if (result.error && result.error === 'expired') {
                notificationService.error('La procédure de modification de mail a expiré. Nous t\'invitons a refaire la modification sur ton compte et à cliquer ensuite sur le lien dans un nouveau mail.');

            }
        } catch (err) {
            notificationService.error('Une erreur est survenue lors de la validation de ton adresse e-mail.');
        }
        navigate(ROUTE_HOME);
    };

    useEffect(() => {
        if (query.get('token')) {
            tokenVerification = query.get('token');
            verifyConfirmationTokenChangementEmail(tokenVerification);
        }
    }, []);

};

export default ChangementEmail;
