import React, { useEffect, useState } from 'react';
import { useResultatsRechercheAdherentes } from '../service/RechercheService';
import Style from 'business/membre/page-membre-list/MembreList.module.css';
import MembreCard from 'business/membre/component/membre-card/MembreCard';
import { useLocation } from 'react-router-dom';
import useMedia from '_common/hook/useMedia';
import Pagination, { COLOR_MEMBRES } from '_common/component/pagination/Pagination';
import { ROUTE_RESULTAT_RECHERCHE_MEMBRE } from '_core/router/routes';
import Loading from '_common/component/icon/Loading';

const ResultatRechercheMembre = () => {
    const query = new URLSearchParams(useLocation().search);
    let termes = query.get('termes');
    let pagenum = query.get('page');
    const [currentPage, setCurrentPage] = useState(parseInt(pagenum || 1, 10));

    const [{ adherentes, nbadherentes, limit }, dataLoaded] = useResultatsRechercheAdherentes(termes, currentPage);
    const last = Math.ceil(nbadherentes / limit);

    const isMobile = useMedia(['(max-width: 425px)', '(min-width: 426px)'], [true, false], true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(parseInt(pagenum || 1, 10));
    }, [pagenum]);

    const handleChangePage = (page) => {
        setCurrentPage(parseInt(page || 1, 10));
        let urlParams = new URLSearchParams();
        urlParams.set('page', page);
        urlParams.set('termes', termes);
        window.history.replaceState(null, null, ROUTE_RESULTAT_RECHERCHE_MEMBRE + '?' + urlParams.toString());
    };

    return (
        <section className={Style.PageContainer}>
            <h1>
                {' '}
                Résultats de la recherche &quot;{termes} pour la rubrique Membres{' '}
                {dataLoaded && <span>({nbadherentes})</span>}
            </h1>

            <div className={Style.BackgroundTitleContainer}>
                <h2 className="sr-only">liste des membres</h2>
                {!dataLoaded ? (
                    <Loading />
                ) : (
                    <>
                        <ul className={Style.CardsContainer}>
                            {adherentes.map(
                                ({ id, nom, prenom, nomEntreprise, profession, ville, photo, territoireCoordonne }) => {
                                    return (
                                        <li key={id}>
                                            <MembreCard
                                                key={id}
                                                id={id}
                                                nom={nom}
                                                prenom={prenom}
                                                nomEntreprise={nomEntreprise}
                                                profession={profession}
                                                ville={ville ? ville.nom : ''}
                                                departement={ville ? ville.departement : ''}
                                                photo={photo}
                                                territoireCoordonne={territoireCoordonne}
                                                isMobile={isMobile}
                                            />
                                        </li>
                                    );
                                },
                            )}
                        </ul>
                    </>
                )}
                {last > 1 && (
                    <Pagination
                        colorEnum={COLOR_MEMBRES}
                        currentPage={currentPage}
                        last={last}
                        changePage={handleChangePage}
                    />
                )}
            </div>
        </section>
    );
};

export default ResultatRechercheMembre;
