import Style from './MembreCard.module.css';
import avatar from '_common/component/icon/avatarMembreDefault.svg';
import { Link, useNavigate } from 'react-router-dom';
import { resolvePath, ROUTE_PROFIL } from '_core/router/routes';
import iconeCoordinatrice from '_common/component/icon/iconeCoordinatrice.svg';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';

const MembreCard = ({
    id,
    isMobile,
    nom,
    prenom,
    nomEntreprise,
    profession,
    ville,
    departement,
    photo,
    territoireCoordonne,
}) => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        if (isMobile) {
            let url = resolvePath(ROUTE_PROFIL, { id });
            navigate(url);
            window.scrollTo(0, 0);
        }
    };

    return (
        <article
            key={territoireCoordonne ? true : ''}
            className={territoireCoordonne ? clsx(Style.Card, Style.isCoordinatrice) : Style.Card}
            onClick={handleCardClick}
        >
            <img
                src={!photo ? avatar : process.env.REACT_APP_URL_IMG_PETITE + photo}
                className={Style.Portrait}
                alt=''
                loading='lazy'
            />
            {territoireCoordonne && (
                <img
                    src={iconeCoordinatrice}
                    key={territoireCoordonne ? true : ''}
                    className={Style.IconeCoordinatrice}
                    alt=''
                />
            )}
            <div className={Style.ContainerNom}>
                <p className={Style.Nom}>
                    {prenom.toLowerCase()} {nom.toLowerCase()}
                </p>
            </div>
            <div className={Style.ContainerCompagnie} aria-label='entreprise'>
                <p className={Style.Compagnie}>{nomEntreprise}</p>
            </div>
            <p className={Style.Domaine} aria-label='profession'>
                {profession}
            </p>
            <div className={Style.Adresse}>
                <p aria-label='ville' className={Style.Ville}>
                    {ville}
                </p>
                {departement && <span>({departement})</span>}
            </div>
            <Link
                to={resolvePath(ROUTE_PROFIL, { id })}
                className={clsx(Style.VoirProfil, 'button-yellow', 'coloured-button', 'button')}
                aria-label={`Voir le profil de ${prenom} ${nom}`}
            >
                Voir le profil
            </Link>
        </article>
    );
};

MembreCard.propTypes = {
    isMobile: PropTypes.bool,
    nom: PropTypes.string,
    prenom: PropTypes.string,
    profession: PropTypes.string,
    nomEntreprise: PropTypes.string,
    id: PropTypes.number.isRequired,
    ville: PropTypes.string,
    photo: PropTypes.string,
    departement: PropTypes.string,
    territoireCoordonne: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default MembreCard;
