import EventEmitter from '_common/service/EventEmitter';
import { useEffect, useState } from 'react';
import useMounted from '_common/hook/useMounted';
import {
    storageObjectRead,
    storageObjectWrite,
    storageRead,
    storageRemove,
    storageWrite,
} from '_common/service/StorageService';
import { get, post, put, set400PlusErrorHandler, setBearerToken, setToken } from '_common/service/ApiService';
import { jwtDecode } from 'jwt-decode';

export const CONNECTED = 'Connected';
export const DISCONNECTED = 'Disconected';
export const NOT_INITIALIZED = 'Not initialized';

let user;
let state = NOT_INITIALIZED;
let token;
user = storageObjectRead('user');
state = storageObjectRead('state');
token = storageRead('token');
let routeLogin = process.env.REACT_APP_API_URL + '/api/login_check';
let routeUser = process.env.REACT_APP_API_URL + '/api/users/me';
let routeRefreshToken = process.env.REACT_APP_API_URL + '/api/token/refresh';
const emiter = new EventEmitter();

export const login = async (username, password) => {
    const resp = await post(routeLogin, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            username,
            password,
        }),
    });
    token = resp;
    state = CONNECTED;
    setBearerToken(token.token);
    storageWrite('token', token.token);
    storageWrite('refreshToken', token.refresh_token);
    storageObjectWrite('state', state);
    const userResp = await getCurrentUser();
    if (
        hasRole(['ROLE_ADHERENTE_ACTIVE'], userResp) ||
        hasRole(['ROLE_ADHERENTE'], userResp) ||
        (user.etapeInscription &&
            hasRole(['ROLE_ADHERENTE_EN_COURS_INSCRIPTION'], user) &&
            user.etapeInscription !== 'termine')
    ) {
        emiter.trigger({ user, state });
    } else {
        throw new Error('Error : 401');
    }
    return userResp;
};

export const loginWithLink = async (url) => {
    const resp = await get(url, {});
    token = resp;
    state = CONNECTED;
    setBearerToken(token.token);
    storageWrite('token', token.token);
    storageWrite('refreshToken', token.refresh_token);
    storageObjectWrite('state', state);
    const userResp = await getCurrentUser();
    if (
        hasRole(['ROLE_ADHERENTE_ACTIVE'], userResp) ||
        hasRole(['ROLE_ADHERENTE'], userResp) ||
        (user.etapeInscription &&
            hasRole(['ROLE_ADHERENTE_EN_COURS_INSCRIPTION'], user) &&
            user.etapeInscription !== 'termine')
    ) {
        emiter.trigger({ user, state });
    } else {
        throw new Error('Error : 401');
    }
    return userResp;
};

export const reloadCurrentUser = async () => {
    const userResp = await getCurrentUser();
    if (
        hasRole(['ROLE_ADHERENTE_ACTIVE'], userResp) ||
        hasRole(['ROLE_ADHERENTE'], userResp) ||
        (user.etapeInscription &&
            hasRole(['ROLE_ADHERENTE_EN_COURS_INSCRIPTION'], user) &&
            user.etapeInscription !== 'termine')
    ) {
        emiter.trigger({ user, state });
    } else {
        throw new Error('Error : 401');
    }
    return userResp;
};

export const getState = () => {
    return state;
};
export const getUser = () => {
    return user;
};

export const logout = (withTriger = true) => {
    user = undefined;
    state = DISCONNECTED;
    withTriger && emiter.trigger({ user, state });
    storageRemove('user');
    storageRemove('token');
    storageRemove('state');
    storageRemove('refreshToken');
    setBearerToken(undefined);
};

export const changeRefreshToken = async (refreshToken, codeHttp) => {
    if (codeHttp === 401 && refreshToken) {
        token = await post(
            routeRefreshToken,
            {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    refresh_token: refreshToken,
                }),
            },
            false,
        );
        if (token.token) {
            setToken(token.token);
        } else {
            logout();
            window.location.reload();
        }
    } else if (codeHttp === 403 || !refreshToken) {
        logout();
    }
};
set400PlusErrorHandler(changeRefreshToken);

export const isTokenExpired = (token) => {
    const decoded = jwtDecode(token);
    return decoded.exp < new Date().getTime() / 1000;
};

export const getCurrentUser = async () => {
    const resp = await get(routeUser, {});
    user = resp;
    storageObjectWrite('user', user);
    return user;
};

let _auth;
export const getAuth = () => _auth;
export const useAuth = () => {
    const [auth, setAuth] = useState({ user, state });
    const mounted = useMounted();
    useEffect(() => {
        return emiter.addListener((auth) => {
            if (mounted) {
                setAuth(auth);
            }
        });
    }, [mounted]);
    _auth = auth;
    return [auth.user, auth.state];
};

let routeSaveEditedAccount;
export const saveEditedInformationsAccount = async (id, valuesForm) => {
    routeSaveEditedAccount = process.env.REACT_APP_API_URL + `/api/adherentes/${id}/parametres`;
    return await put(routeSaveEditedAccount, {
        body: JSON.stringify({
            id: id,
            prenom: valuesForm.nouveauPrenom,
            nom: valuesForm.nouveauNom,
            villeId: valuesForm.nouvelleVille,
        }),
    });
};

export const saveEditedEmailAccount = async (valuesForm) => {
    routeSaveEditedAccount = process.env.REACT_APP_API_URL + '/api/new_email';
    return await put(routeSaveEditedAccount, {
        body: JSON.stringify({
            email: valuesForm.nouveauEmail,
        }),
    });
};

export const saveEditedPasswordAccount = async (id, valuesForm) => {
    routeSaveEditedAccount = process.env.REACT_APP_API_URL + '/api/new_password';
    return await put(routeSaveEditedAccount, {
        body: JSON.stringify({
            id: id,
            password: valuesForm.currentPassword,
            newPassword: valuesForm.newPassword,
        }),
    });
};

export const hasRole = (roles, user) => {
    if (user && roles && roles.length > 0) {
        for (let i = 0; i < roles.length; i++) {
            const role = roles[i];
            for (let j = 0; j < user.roles.length; j++) {
                const userRole = user.roles[j];
                if (role === userRole) {
                    return true;
                }
            }
        }
        return false;
    } else {
        return true;
    }
};

export const saveEditedNotificationPreference = async (valuesForm) => {
    routeSaveEditedAccount = process.env.REACT_APP_API_URL + '/api/notifications/preferences';
    const formData = new FormData();
    formData.append('notificationMessagerie', valuesForm.notificationMessagerie);
    formData.append('notificationMention', valuesForm.notificationMention);
    formData.append('notificationEvenement', valuesForm.notificationEvenement);
    formData.append('notificationReponseCoupDePouce', valuesForm.notificationReponseCoupDePouce);
    return await post(routeSaveEditedAccount, {
        body: formData,
    });
};

let routeLienConnexion = process.env.REACT_APP_API_URL + '/api/inscription/lien_connexion';
export const envoiLienConnexion = async (email) => {
    const formData = new FormData();
    formData.append('email', email);
    return await post(routeLienConnexion, {
        body: formData,
    });
};
