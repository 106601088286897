import Style from '../header/AppHeader.module.css';
import loupe from '_common/component/icon/search.svg';
import React, { useEffect, useState } from 'react';
import { ROUTE_RESULTAT_RECHERCHE_GLOBALE } from '_core/router/routes';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const Recherche = ({ isAdherenteActive }) => {
    const query = new URLSearchParams(useLocation().search);
    let termes = query.get('termes');
    const [inputMot, setInputMot] = useState(termes ?? '');

    const handleNameChange = (e) => {
        setInputMot(e.target.value);
    };

    const onClickSearch = (e) => {
        e.preventDefault();
        if (inputMot.length > 0) {
            let urlParams = new URLSearchParams();
            urlParams.set('termes', inputMot);
            window.location.href = ROUTE_RESULTAT_RECHERCHE_GLOBALE + '?' + urlParams.toString();
        }
    };

    useEffect(() => {
        if (termes == null) {
            setInputMot('')
        } else {
            setInputMot(termes)
        }
    }, [termes])

    return (
        <form className={Style.SearchControl} role="search" aria-label="Rechercher" onSubmit={onClickSearch}>
            <label className="sr-only" htmlFor="search">
                Rechercher un nom ou un mot clef
            </label>
            <input
                id="search"
                className={Style.SearchInput}
                type="text"
                placeholder={'Rechercher un nom ou un mot clef'}
                value={inputMot}
                onChange={handleNameChange}
                disabled={!isAdherenteActive}
            />
            <input
                type="image"
                src={loupe}
                alt="Lancer la recherche"
                className={Style.SearchIcon}
                disabled={!isAdherenteActive}
            />
        </form>
    );
};

Recherche.propTypes = {
    isAdherenteActive: PropTypes.bool,
};

export default Recherche;
