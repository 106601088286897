import Style from './AuthentificationConnexion.module.css';
import { useEffect, useState } from 'react';
import { login, hasRole, loginWithLink, envoiLienConnexion, getAuth } from '_core/authentification/service/AuthService';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
    ROUTE_INSCRIPTION,
    ROUTE_REINITIALISATION_MDP,
    ROUTE_FIN_ADHESION,
    ROUTE_HOME,
    ROUTE_CONNEXION,
} from '_core/router/routes';
import connexionImage from '_common/component/icon/leftSideLogin.jpg';
import vagueLogin from '_common/component/icon/vagueLoginDesktop.svg';
import LogoFdt from '_common/component/icon/logoFDT.svg';
import clsx from 'clsx';
import eye from '_common/component/icon/eye.svg';
import notificationService from '_common/component/toaster/NotificationService';

const AuthentificationConnexion = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [error, setError] = useState('');
    const [errorLink, setErrorLink] = useState('');
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const [lienConnexionExpire, setLienConnexionExpire] = useState(false);
    const [isSubmitButtonClickable, setIsSubmitButtonClickable] = useState(true);
    const [isAncienLienConnexion, setIsAncienLienConnexion] = useState(false);

    let lienConnexionDecode = null;

    let emailMembre = null;
    if (query.get('lienConnexion')) {
        lienConnexionDecode = decodeURI(query.get('lienConnexion'));
        const url = new URL(lienConnexionDecode);
        emailMembre = new URLSearchParams(url.search).get('user');
    }


    let auth = getAuth();
    const userDejaConnecte = auth.user;


    const loginWithoutPassword = async (lienConnexionDecode) => {
        try {
            const user = await loginWithLink(lienConnexionDecode);
            if (user) {
                navigate(ROUTE_HOME);
            }
        } catch (err) {
            setLienConnexionExpire(true);
        }
    };

    useEffect(() => {
        if (userDejaConnecte) {
            navigate(ROUTE_HOME);
        }

        if (lienConnexionDecode) {
            loginWithoutPassword(lienConnexionDecode);
        }

        if (
            window.location.href.match(ROUTE_CONNEXION) &&
            window.location.pathname.includes('token=') &&
            window.location.pathname.includes('creation=')
        ) {
            setLienConnexionExpire(true);
            setIsAncienLienConnexion(true);
        }
    }, []);

    const submit = async (e) => {
        e.preventDefault();
        try {
            const user = await login(email, password);
            if (user && hasRole(['ROLE_ADHERENTE'], user) && !hasRole(['ROLE_ADHERENTE_ADHESION_ACTIVE'], user)) {
                navigate(ROUTE_FIN_ADHESION);
            } else {
                window.location.reload();
            }
        } catch (err) {
            if (err.message === '403') {
                setError(
                    '<p>Erreur : Veuillez valider votre adresse e-mail.</p><p>Nous vous avons envoyé un e-mail de confirmation le jour de votre inscription (pensez à vérifier dans vos Spams).<br />Pour finaliser votre enregistrement sur le réseau Femmes des Territoires, ouvrez cet e-mail et cliquez sur le lien de validation.</p><p>À très vite !<br />L\'équipe Femmes des Territoires 🌻</p>',
                );
            } else {
                setError('<p>Erreur : Veuillez entrer des identifiants valides.<p>');
            }
            setEmail('');
            setPassword('');
        }
    };

    const submitNewLink = async (e) => {
        e.preventDefault();
        setIsSubmitButtonClickable(false);
        try {
            await envoiLienConnexion(isAncienLienConnexion ? email : emailMembre);
            notificationService.success('Le lien vient d\'être envoyé dans ta boite mail');
            setIsSubmitButtonClickable(true);
        } catch (err) {
            setErrorLink('Une erreur est survenue.');
        }
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <div className={Style.ContainerLogin}>
            <h1 className="sr-only">Connexion à Femmes des Territoires</h1>
            <div className={Style.Content}>
                <div className={Style.LeftSideLogin}>
                    <div className={Style.ImageBackground} />
                    <img src={connexionImage} alt="" />
                </div>
                <div className={Style.RightSideLogin}>
                    <img className={Style.LogoFdt} src={LogoFdt} alt="" aria-hidden="true" />
                    {lienConnexionExpire ? (
                        <>
                            <h2 className="sr-only">Expiration du lien de connexion</h2>
                            <form className={Style.newLinkForm}>
                                <p>Le lien est expiré !</p>
                                {errorLink && <p className="red-error">{errorLink}</p>}
                                {isAncienLienConnexion && (
                                    <label className={clsx(Style.Label, 'label-input')} htmlFor="emailMembre" lang="en">
                                        Indique ici l&apos;adresse email à laquelle te renvoyer un lien (cette adresse
                                        doit être identique à celle que tu as utilisée pour t&apos;inscrire)
                                    </label>
                                )}
                                <input
                                    id="emailMembre"
                                    type={isAncienLienConnexion ? 'email' : 'hidden'}
                                    className={clsx('input-field', Style.EmailInputField)}
                                    name="emailMembre"
                                    value={isAncienLienConnexion ? email : emailMembre}
                                    onChange={(e) => isAncienLienConnexion ?? setEmail(e.target.value)}
                                    autoComplete="email"
                                />
                                <button
                                    className={clsx(Style.ButtonConnexion, 'button-green', 'coloured-button', 'button')}
                                    type="submit"
                                    onClick={submitNewLink}
                                    disabled={!isSubmitButtonClickable}
                                >
                                    Recevoir un nouveau lien
                                </button>
                                <p>
                                    Si tu n’as toujours pas reçu de mail après quelques minutes, pense à vérifier dans
                                    les spams ou contacte nous à contact@femmesdesterritoires.fr
                                </p>
                                <p>Attention, le lien a une validité de 1 heure.</p>
                            </form>
                        </>
                    ) : (
                        <>
                            <h2 className="sr-only">Se Connecter</h2>
                            <p className={Style.BeforeInscrivezVous}>
                                {`Si vous n'avez pas encore de compte,`}{' '}
                                <Link
                                    className={Style.InscrivezVous}
                                    to={ROUTE_INSCRIPTION}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    inscrivez-vous !
                                </Link>
                            </p>

                            <form>
                                {error && (
                                    <div
                                        className={clsx(Style.WarningErrorContainer)}
                                        dangerouslySetInnerHTML={{
                                            __html: error,
                                        }}
                                    ></div>
                                )}
                                <label className={clsx(Style.Label, 'label-input')} htmlFor="a11y-email" lang="en">
                                    E-mail
                                </label>
                                <input
                                    id="a11y-email"
                                    autoComplete="email"
                                    type="email"
                                    className={clsx('input-field', Style.EmailInputField)}
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label className={clsx(Style.Label, 'label-input')} htmlFor="a11y-password">
                                    Mot de Passe
                                </label>
                                <div className={Style.ContainerPassword}>
                                    <input
                                        id="a11y-password"
                                        autoComplete="current-password"
                                        type={passwordShown ? 'text' : 'password'}
                                        className="input-field"
                                        value={password}
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        className={`button-icon ${Style.buttonEye}`}
                                        onClick={togglePasswordVisiblity}
                                    >
                                        <img src={eye} alt="" aria-hidden="true" />
                                        <span className="sr-only">
                                            {passwordShown ? 'cacher' : 'voir'} le mot de passe
                                        </span>
                                    </button>
                                </div>
                                <p>
                                    <Link
                                        className={Style.MotDePasseOublie}
                                        to={ROUTE_REINITIALISATION_MDP}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Mot de passe oublié
                                    </Link>
                                </p>
                                <div className={Style.ButtonContainer}>
                                    <button
                                        className={clsx(
                                            Style.ButtonConnexion,
                                            'button-green',
                                            'coloured-button',
                                            'button',
                                        )}
                                        type="submit"
                                        onClick={submit}
                                    >
                                        Connexion
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
            <img src={vagueLogin} alt="" className={Style.VagueLogin} loading="lazy" />
        </div>
    );
};

export default AuthentificationConnexion;
