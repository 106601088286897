import clsx from "clsx";
import Style from "./Pagination.module.css";
import React from "react";
import PropTypes from "prop-types";

export const COLOR_MEMBRES = "yellow";
export const COLOR_COUPS_DE_POUCE = "green";
export const COLOR_EVENEMENTS = "pink";

const Pagination = ({ changePage, currentPage, last, colorEnum }) => {
  let pagesToShow = 3;
  let startFromNumber;
  let showFirstPageButton;
  let showLastPageButton;
  const getPages = () => {
    const pages = [];

    if (last <= pagesToShow) {
      startFromNumber = 1;
      pagesToShow = last;
    } else if (currentPage <= Math.ceil(pagesToShow / 2)) {
      startFromNumber = 1;
    } else if (currentPage + Math.floor((pagesToShow - 1) / 2) >= last) {
      startFromNumber = last - (pagesToShow - 1);
    } else {
      startFromNumber = currentPage - Math.floor(pagesToShow / 2);
    }
    for (let i = 1; i <= pagesToShow; i += 1) {
      pages.push(startFromNumber);
      startFromNumber += 1;
    }

    return pages;
  };
  const computedPages = getPages();
  showFirstPageButton = computedPages.indexOf(1) === -1;
  showLastPageButton = computedPages.indexOf(last) === -1;

  if (last === currentPage && last === 1) {
    return null;
  }

  return (
    <>
      <nav className={Style.paginationIsCentered} role="navigation" aria-label="pagination">
        <button
          className={clsx(Style.paginationPrevious, Style["ColouredButtons_" + colorEnum])}
          onClick={() => changePage(currentPage - 1)}
          disabled={currentPage <= 1}
        >
          Avant
        </button>

        <ul className={Style.paginationList}>
          {showFirstPageButton ? (
            <>
              <li id="first-page" key="first-page">
                <button
                  onClick={() => changePage(1)}
                  className={clsx(Style.paginationLink, Style["ColouredButtons_" + colorEnum])}
                  type="button"
                  aria-label="Goto page 1"
                >
                  1
                </button>
              </li>
              <li key="first-page-ellipsis">
                <span className={Style.paginationEllipsis}>&hellip;</span>
              </li>
            </>
          ) : null}

          {computedPages.map((page) => {
            return (
              <li key={page}>
                <button
                  className={clsx(
                    Style.paginationLink,
                    Style["ColouredButtons_" + colorEnum + (page === currentPage ? "_active" : "")]
                  )}
                  aria-label={`Page ${page}`}
                  onClick={() => {
                    changePage(page);
                  }}
                >
                  {page}
                </button>
              </li>
            );
          })}
          {showLastPageButton ? (
            <>
              <li key="last-page-ellipsis">
                <span className={Style.paginationEllipsis}>&hellip;</span>
              </li>
              <li id="last-page" key="last-page">
                <button
                  onClick={() => changePage(last)}
                  className={clsx(Style.paginationLink, Style["ColouredButtons_" + colorEnum])}
                  aria-label={`Goto page ${last}`}
                >
                  {last}
                </button>
              </li>
            </>
          ) : null}
        </ul>
        <button
          onClick={() => changePage(currentPage + 1)}
          className={clsx(Style.paginationNext, Style["ColouredButtons_" + colorEnum])}
          disabled={currentPage >= last}
        >
          Après
        </button>
      </nav>
    </>
  );
};

Pagination.propTypes = {
  changePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired,
  colorEnum: PropTypes.string.isRequired,
};

export default React.memo(Pagination);
