import { useEffect, useState } from 'react';
import {
    getResultatsRechercheAdherentes,
    getResultatsRechercheCoupDePouces,
    getResultatsRechercheEvenements,
    getResultatsRechercheGlobale,
    getResultatsRecherchePublications,
    getResultatsRechercheRessources,
    getResultatsRechercheTerritoires,
} from './RechercheServiceApi';
import useRefresh from '_common/hook/useRefresh';

export const useResultatsRechercheGlobale = (termes) => {
    const [
        {
            coupsdepouce,
            adherentes,
            evenements,
            publications,
            territoires,
            ressources,
            nbcoupsdepouce,
            nbadherentes,
            nbevenements,
            nbpublications,
            nbterritoires,
            nbressources,
            nbTotalResultats,
        },
        setResultatsRechercheGlobale,
    ] = useState({ resultats: [] });
    const [dataLoaded, setDataLoaded] = useState(false);
    const [refresh, count] = useRefresh();

    useEffect(() => {
        getResultatsRechercheGlobale(termes).then((resultats) => {
            setResultatsRechercheGlobale(resultats);
            setDataLoaded(true);
        });
    }, [termes, count]);
    return [
        {
            coupsdepouce,
            adherentes,
            evenements,
            publications,
            territoires,
            ressources,
            nbcoupsdepouce,
            nbadherentes,
            nbevenements,
            nbpublications,
            nbterritoires,
            nbressources,
            nbTotalResultats,
        },
        dataLoaded,
        refresh,
    ];
};

export const useResultatsRechercheAdherentes = (termes, pagenum) => {
    const [{ adherentes, nbadherentes, limit }, setResultatsRechercheAdherentes] = useState({
        adherentes: [],
        nbadherentes: 0,
        limit: null,
    });
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        getResultatsRechercheAdherentes(termes, pagenum).then((resultats) => {
            setResultatsRechercheAdherentes({
                adherentes: resultats.resultats,
                nbadherentes: resultats.nbTotalResultats,
                limit: resultats.limit,
            });
            setDataLoaded(true);
        });
    }, [termes, pagenum]);
    return [{ adherentes, nbadherentes, limit }, dataLoaded];
};

export const useResultatsRechercheCoupDePouces = (termes, pagenum) => {
    const [{ coupsdepouce, nbcoupsdepouce, limit }, setResultatsRechercheCoupDePouces] = useState({
        coupsdepouce: [],
        nbcoupsdepouce: 0,
        limit: null,
    });
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        getResultatsRechercheCoupDePouces(termes, pagenum).then((resultats) => {
            setResultatsRechercheCoupDePouces({
                coupsdepouce: resultats.resultats,
                nbcoupsdepouce: resultats.nbTotalResultats,
                limit: resultats.limit,
            });
            setDataLoaded(true);
        });
    }, [termes, pagenum]);
    return [{ coupsdepouce, nbcoupsdepouce, limit }, dataLoaded];
};

export const useResultatsRechercheEvenements = (termes, pagenum) => {
    const [{ evenements, nbevenements, limit }, setResultatsRechercheEvenements] = useState({
        evenements: [],
        nbevenements: 0,
        limit: null,
    });
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        getResultatsRechercheEvenements(termes, pagenum).then((resultats) => {
            setResultatsRechercheEvenements({
                evenements: resultats.resultats,
                nbevenements: resultats.nbTotalResultats,
                limit: resultats.limit,
            });
            setDataLoaded(true);
        });
    }, [termes, pagenum]);
    return [{ evenements, nbevenements, limit }, dataLoaded];
};

export const useResultatsRecherchePublications = (termes, pagenum) => {
    const [{ publications, nbpublications, limit }, setResultatsRecherchePublications] = useState({
        publications: [],
        nbpublications: 0,
        limit: null,
    });
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        getResultatsRecherchePublications(termes, pagenum).then((resultats) => {
            setResultatsRecherchePublications({
                publications: resultats.resultats,
                nbpublications: resultats.nbTotalResultats,
                limit: resultats.limit,
            });
            setDataLoaded(true);
        });
    }, [termes, pagenum]);
    return [{ publications, nbpublications, limit }, dataLoaded];
};

export const useResultatsRechercheTerritoires = (termes, pagenum) => {
    const [{ territoires, nbterritoires, limit }, setResultatsRechercheTerritoires] = useState({
        territoires: [],
        nbterritoires: 0,
        limit: null,
    });
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        getResultatsRechercheTerritoires(termes, pagenum).then((resultats) => {
            setResultatsRechercheTerritoires({
                territoires: resultats.resultats,
                nbterritoires: resultats.nbTotalResultats,
                limit: resultats.limit,
            });
            setDataLoaded(true);
        });
    }, [termes, pagenum]);

    return [{ territoires, nbterritoires, limit }, dataLoaded];
};

export const useResultatsRechercheRessources = (termes, pagenum) => {
    const [{ ressources, nbressources, limit }, setResultatsRechercheRessources] = useState({
        ressources: [],
        nbressources: 0,
        limit: null,
    });
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        getResultatsRechercheRessources(termes, pagenum).then((resultats) => {
            setResultatsRechercheRessources({
                ressources: resultats.resultats,
                nbressources: resultats.nbTotalResultats,
                limit: resultats.limit,
            });
            setDataLoaded(true);
        });
    }, [termes, pagenum]);
    return [{ ressources, nbressources, limit }, dataLoaded];
};
