import { get } from '_common/service/ApiService';
import EventEmitter from '_common/service/EventEmitter';
import { CONNECTED, getState, getAuth, hasRole } from '_core/authentification/service/AuthService';

export const getUserMe = async () => {
    return await get(process.env.REACT_APP_API_URL + '/api/users/me');
};

export const getCheckLastMessageDatetime = async () => {
    return await get(process.env.REACT_APP_API_URL + `/api/discussions/check`);
};

export const getCheckLastNotificationDatetime = async () => {
    return await get(process.env.REACT_APP_API_URL + `/api/notifications/check`);
};

const DEV = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

const messagerieEmitter = new EventEmitter();
export const addMessagerieListener = (listener) => messagerieEmitter.addListener(listener);
export const triggerMessagerieChanged = (message) => messagerieEmitter.trigger(message);
export const initMessagerieListener = async () => {
    const messagerieListener = async () => {
        let auth = getAuth();
        const isConnected = getState() === CONNECTED;
        const user = auth.user;
        if (!auth || !isConnected || !hasRole(['ROLE_ADHERENTE_ACTIVE'], user)) {
            return;
        }

        let check;
        check = await getCheckLastMessageDatetime();
        triggerMessagerieChanged(check);
    };
    setInterval(messagerieListener, DEV ? 1_000_000 : 5000);
    messagerieListener();
};

const notificationEmitter = new EventEmitter();
export const addNotificationListener = (listener) => notificationEmitter.addListener(listener);
export const triggerNotificationChanged = (notification) => notificationEmitter.trigger(notification);
export const initNotificationListener = async () => {
    const notificationListener = async () => {
        let auth = getAuth();
        const isConnected = getState() === CONNECTED;
        const user = auth.user;
        if (!auth || !isConnected || !hasRole(['ROLE_ADHERENTE_ACTIVE'], user)) {
            return;
        }
        let check;
        check = await getCheckLastNotificationDatetime();
        triggerNotificationChanged(check);
    };

    setInterval(notificationListener, DEV ? 1_000_000 : 5000);
    notificationListener();
};
